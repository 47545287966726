$desktop-width: 62em;
$tablet-width: 20em;
$desktop-height: 50em;
$dark-blue: #003da5;
$light-blue: #ccd8ed;
$folder-yellow: #ffc845;
$file-icon-bg : #57ade6;
$red: #e4002b;
$green: #009f4d;
$black: #000000;

@mixin center-self {
    align-self: center;
    margin-right: auto;
    margin-left: auto;
    display: block;
}

@mixin prevent-select {
    //https: //stackoverflow.com/a/880518/6731964
    user-select: none;
    /* standard syntax */
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
}

@mixin font-lining-numerals {
    -webkit-font-feature-settings: "lnum" !important;
}
