// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '/src/variables' as v;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rpc-ui-primary: mat.define-palette(mat.$indigo-palette);
$rpc-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$rpc-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$rpc-ui-theme: mat.define-light-theme((color: (primary: $rpc-ui-primary,
        accent: $rpc-ui-accent,
        warn: $rpc-ui-warn,
      ),
      typography: mat.define-typography-config($font-family: "Raleway",
        $body-2: mat.define-typography-level(14px, normal, normal),
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($rpc-ui-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  background-color: v.$dark-blue !important;
}

@media (max-width: 768px) {

  ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}




body {
  margin: 0;
  font-family: Raleway !important;
  @include v.font-lining-numerals();
  width: 100%;

  @media (min-width: v.$tablet-width) {
    height: 85%;
  }

  @media (min-width: v.$desktop-width) {
    height: 100%;
  }
}

.mat-grid-tile .mat-grid-tile-content {
  justify-content: flex-start;
  align-items: flex-start;
  display: unset;
}

//  Scroll bar handle

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #008fa5;
  border-radius: 10px;
  border: 2.5px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover{
  border: 0;
}

a {
  color: v.$dark-blue;
  font-weight: bold;
}

.font-normal {
  font-family: Raleway;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: v.$dark-blue;
  @include v.font-lining-numerals();
}

.padding-left-2-percent {
  padding-left: 2%;
}

input:focus-visible {
  outline: 1px solid #17b3e3;
}

.tab-section-margin {
  margin-left: 15px;
}

.button {
  border-radius: 8px;
  background-color: #fff;
  color: #000;
}

/* modal styles */
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(3px);
}

::ng-deep .mat-mdc-dialog-container {
  border-radius: 10px;
}

.dialog-title {
  color: v.$dark-blue;
  font-weight: bold;
  font-size: 18px;
}

.mat-mdc-dialog-content {
  font-size: 16px;
  overflow: initial;
}

.mat-mdc-button-focus-overlay {
  background-color: transparent !important;
}

.mat-expansion-panel-content {
  color: v.$dark-blue;
  @include v.font-lining-numerals();
}

.mdc-checkbox {
  padding: 0px !important;
  height: 16px !important;
}

.mdc-checkbox__background {
  top: 0px !important;
  left: 0px !important;
}

.mat-mdc-checkbox-touch-target {
  height: 0px !important;
  widows: 0px !important;
}

.mat-mdc-button-base {
  padding: 0px !important;
  height: 24px !important;
}

.mat-mdc-button-touch-target {
  padding: 0px !important;
  height: 24px !important;
}

.mat-tree-node {
  min-height: 24px !important;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  color: #107c10 !important;
}

input[type='checkbox'] {
  top: 0px !important;
  right: 0px !important;
  left: 0px !important;
  height: 16px !important;
  width: 16px !important;
}

.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected{
  --mdc-chip-label-text-color: #000;
  --mdc-chip-with-trailing-icon-trailing-icon-color:   #000;
 
}

.mat-mdc-chip.cdk-focused .mat-mdc-chip-focus-overlay {
  opacity: 0 !important;
}

